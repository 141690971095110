<template>
  <div id="Navbar">
    <div class="sticky-header main-bar-wraper navbar-expand-lg">
      <div class="main-bar">
        <div class="container clearfix">
          <div class="logo-header">
            <div class="logo-header-inner logo-header-one">
              <a href="/">
                <img src="images/logo-claro-2.png" alt="" />
              </a>
            </div>
          </div>
          <!-- NAV Toggle Button -->
          <button data-target=".header-nav" data-toggle="collapse" type="button" class="navbar-toggler collapsed">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>

          <!-- MAIN NAVIGATION -->
          <div
            class="header-nav nav-dark navbar-collapse collapse justify-content-center collapse"
            :class="{ display: display, '': display }"
            @click.stop="goRoute()"
          >
            <ul class="nav navbar-nav" style="display: flex; justify-content: center; align-items: center">
              <li>
                <router-link to="/" style="display: flex; justify-content: center; align-items: center">INICIO</router-link>
              </li>
              <li>
                <router-link to="/about" style="display: flex; justify-content: center; align-items: center">SOBRE NOSOTROS</router-link>
              </li>
              <li><a href="/#/product" style="display: flex; justify-content: center; align-items: center">PRODUCTOS</a></li>
              <li>
                <router-link to="/project" style="display: flex; justify-content: center; align-items: center">PROYECTOS</router-link>
              </li>
              <li><router-link to="/contact" style="display: flex; justify-content: center; align-items: center">CONTACTO</router-link></li>
              <li class="isBotton">
                <a
                  href="images/product/dataSheet/CATALOGOCOVERCHILEE.pdf"
                  style="display: flex; justify-content: center; align-items: center"
                  class="buttonDownload"
                  target="_blank"
                  download
                  >DESCARGAR CATALOGO</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navbarScroll",
  data() {
    return { display: "", collapse: true };
  },
  methods: {
    goRoute() {
      window.scrollTo(0, 0);

      if (this.display === "") {
        return (this.display = "show");
      }
      this.display = "";
    },
  },
};
</script>

<style scoped>
.isBotton {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 10px;
}
.isBotton:hover {
  background: #86b5d3;
  border: 2px solid #fff;
}
</style>
