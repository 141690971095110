<template>
  <div id="scrollTop">
    <button class="scroltop"><span class="fa fa-angle-up relative" id="btn-vibrate"></span></button>
  </div>
</template>

<script>
export default {
  name: "scrollTop",
};
</script>

<style></style>
