<template>
  <HeaderBanner></HeaderBanner>
  <ProjectCarrusel></ProjectCarrusel>
  <ProjectInformation></ProjectInformation>
  <!-- <TestimonialClients></TestimonialClients> -->
</template>

<script>
import HeaderBanner from "../components/projects/HeaderBanner";
import ProjectInformation from "../components/projects/ProjectInformation";
import ProjectCarrusel from "../components/projects/ProjectCarrusel";
// import TestimonialClients from "../components/projects/TestimonialClients";

export default {
  name: "project",
  components: {
    HeaderBanner,
    ProjectInformation,
    ProjectCarrusel,
    // TestimonialClients,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style></style>
