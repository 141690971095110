<template>
  <Banner></Banner>
  <About></About>
  <Product></Product>
  <Project></Project>
  <Contact></Contact>
</template>

<script>
import Banner from "../components/home/Banner";
import Project from "../components/home/Project";
import Product from "../components/home/Product";
import About from "../components/home/About";
import Contact from "../components/home/Contact";
export default {
  name: "Home",
  components: {
    Banner,
    Project,
    About,
    Contact,
    Product,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
