<template>
  <!-- FOOTER START -->
  <footer class="site-footer footer-large footer-dark footer-wide">
    <!-- FOOTER BLOCKES START -->
    <div class="footer-top overlay-wraper">
      <div class="overlay-main"></div>
      <div class="container">
        <div class="row">
          <!-- ABOUT COMPANY -->
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="widget widget_about">
              <!--<h4 class="widget-title">About Company</h4>-->
              <div class="logo-footer clearfix p-b15">
                <a href="/"><img src="images/logo-claro-2.png" alt="" height="53px" /></a>
              </div>
              <p>
                Es una empresa ubicada en la X Región de Los Lagos, orientada a entregar a sus clientes una solución integral a través de la
                provisión e Instalación de Cubiertas y Revestimientos de acero pintados, como también de paneles Aislados. Contamos con más
                de 2 años de experiencia, otorgando excelencia en el servicio y calidad en los productos.
              </p>

              <ul class="social-icons sx-social-links">
                <li>
                  <a
                    href=" https://www.facebook.com/coverchilecl/?ref=page_internal"
                    style="display: flex; justify-content: center; align-items: center"
                    target="_blank"
                  >
                    <i class="fab fa-facebook" style="font-size: 18px"></i
                  ></a>
                </li>
                <li style="margin: 0px 10px">
                  <a
                    href="https://www.linkedin.com/company/coverchile/mycompany/"
                    style="display: flex; justify-content: center; align-items: center"
                    target="_blank"
                  >
                    <i class="fab fa-linkedin" style="font-size: 18px"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/coverchile.cl/?hl=es-la"
                    target="_blank"
                    style="display: flex; justify-content: center; align-items: center"
                    ><i class="fab fa-instagram" style="font-size: 18px"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>

          <!-- CONTACT US -->
          <div class="col-lg-6 col-md-6 col-sm-6" style="display: flex">
            <div class="widget widget_address_outer">
              <h5 class="widget-title">CONTACTO</h5>
              <ul class="widget_address">
                <li style="font-weight: 600">Puerto varas</li>
                <li style="font-weight: 600">Lote 112 sector parque empresarial La foresta,</li>
                <li style="font-weight: 600">sector el arrayán, ruta 5 sur.</li>
                <li style="font-weight: 600">contacto@coverchile.cl</li>
                <li style="font-weight: 600">+56 9 5687 5957</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FOOTER COPYRIGHT -->
    <div class="footer-bottom overlay-wraper">
      <div class="overlay-main"></div>
      <div class="container">
        <div class="row">
          <div class="sx-footer-bot-left">
            <span class="copyrights-text">© 2021 Cover Chile. Designed By E-Virtus.</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- FOOTER END -->
</template>

<script>
export default {
  name: "footer",
};
</script>

<style></style>
