<template>
  <!-- OUR AWARDS SECTION START -->
  <div
    class="section-full p-t80 p-b50 sx-bg-secondry bg-no-repeat bg-bottom-center mobile-page-padding"
    style="background-image: url(images/background/bg-map.png)"
  >
    <div class="container">
      <!-- TITLE START -->
      <div class="section-head">
        <div class="sx-separator-outer separator-center">
          <div class="sx-separator bg-white bg-moving bg-repeat-x" style="background-image: url(images/background/cross-line2.png)">
            <h3 class="sep-line-one" style="text-align: center">HISTORIAL DE PROYECTOS</h3>
          </div>
        </div>
      </div>
      <!-- TITLE END -->

      <div class="section-content">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12 m-b30">
            <div class="sx-box our-story text-white" style="height: 105px">
              <div class="mt-media our-story-info d-flex justify-content-start">
                <div class="our-story-time"><span class="date">01</span></div>
                <h4 class="text-uppercase our-story-year">2019</h4>
              </div>
              <div class="our-story-detail">
                <h4>Edificio Avant Garde</h4>
              </div>
            </div>
            <div class="sx-box our-story text-white" style="height: 105px">
              <div class="mt-media our-story-info d-flex justify-content-start">
                <div class="our-story-time"><span class="date">02</span></div>
                <h4 class="text-uppercase our-story-year">2020</h4>
              </div>
              <div class="our-story-detail">
                <h4>Mall Chino - Quilpue</h4>
              </div>
            </div>
            <div class="sx-box our-story text-white" style="height: 105px">
              <div class="mt-media our-story-info d-flex justify-content-start">
                <div class="our-story-time"><span class="date">03</span></div>
                <h4 class="text-uppercase our-story-year">2021</h4>
              </div>
              <div class="our-story-detail">
                <h4>Edificios Rocura II - Valdivia</h4>
              </div>
            </div>
            <!-- <div class="sx-box our-story text-white">
              <div class="mt-media our-story-info d-flex justify-content-start">
                <div class="our-story-time"><span class="date">28</span><span class="month">Aug</span></div>
                <h4 class="text-uppercase our-story-year">2014</h4>
              </div>
              <div class="our-story-detail">
                <h4>International Fine Art</h4>
                <p>Don’t know how to go with plan, give everything to interior design with calm.</p>
              </div>
            </div>
            <div class="sx-box our-story text-white">
              <div class="mt-media our-story-info d-flex justify-content-start">
                <div class="our-story-time"><span class="date">20</span><span class="month">Oct</span></div>
                <h4 class="text-uppercase our-story-year">2020 - 2021</h4>
              </div>
              <div class="our-story-detail">
                <h4>Newyork Creative Art</h4>
                <p>Every work has its professional which can perform their task with all the best standards.</p>
              </div>
            </div> -->
          </div>

          <div class="col-xl-6 col-lg-6 col-md-12" style="height: 105px">
            <div class="sx-box our-story text-white" style="height: 105px">
              <div class="mt-media our-story-info d-flex justify-content-start">
                <div class="our-story-time"><span class="date">04</span></div>
                <h4 class="text-uppercase our-story-year">2020 2021</h4>
              </div>
              <div class="our-story-detail">
                <h4>Edificio Nomada - Puerto Varas</h4>
              </div>
            </div>
            <div class="sx-box our-story text-white" style="height: 105px">
              <div class="mt-media our-story-info d-flex justify-content-start">
                <div class="our-story-time"><span class="date">05</span></div>
                <h4 class="text-uppercase our-story-year">2020 2021</h4>
              </div>
              <div class="our-story-detail">
                <h4>Edificios Sargento Silva - Puerto Montt</h4>
              </div>
            </div>
            <div class="sx-box our-story text-white" style="height: 105px">
              <div class="mt-media our-story-info d-flex justify-content-start">
                <div class="our-story-time"><span class="date">08</span></div>
                <h4 class="text-uppercase our-story-year">2019 2020</h4>
              </div>
              <div class="our-story-detail">
                <h4>Mall Costanera - Puerto Montt</h4>
              </div>
            </div>

            <!--   <div class="sx-box our-story text-white">
              <div class="mt-media our-story-info d-flex justify-content-start">
                <div class="our-story-time"><span class="date">19</span><span class="month">July</span></div>
                <h4 class="text-uppercase our-story-year">2017</h4>
              </div>
              <div class="our-story-detail">
                <h4>Asia Creative Competition</h4>
                <p>Give your consent, we design a perfect bend choose the style, we complete with our file</p>
              </div>
            </div>
            <div class="sx-box our-story text-white">
              <div class="mt-media our-story-info d-flex justify-content-start">
                <div class="our-story-time"><span class="date">27</span><span class="month">May</span></div>
                <h4 class="text-uppercase our-story-year">2018</h4>
              </div>
              <div class="our-story-detail">
                <h4>Center of Gloucester</h4>
                <p>Scrambled it to make a type specimen book. remaining essentially.</p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- OUR AWARDS SECTION END -->
</template>

<script>
export default {};
</script>

<style></style>
