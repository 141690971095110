<template>
  <div id="homeAbout">
    <!-- ABOUT COMPANY START -->
    <div class="section-full mobile-page-padding p-t80 p-b50 bg-gray">
      <div class="container">
        <div class="section-content">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12">
              <div class="about-home-3 m-b30 bg-white">
                <h3 class="m-t0 m-b20 sx-tilte">COVER CHILE</h3>
                <p>
                  Nuestra experiencia obtenida a través de los años nos permite entregar productos de calidad para realizar todo tipo de
                  proyectos, considerando siempre:
                </p>

                <ul class="list-angle-right anchor-line">
                  <li><a href="/product">Empleo de materias primas de calidad.</a></li>
                  <li><a href="/product">Fabricación a cargo de especialistas.</a></li>
                  <li><a href="/project">Cumplimiento en la entrega.</a></li>
                </ul>

                <div class="text-left">
                  <router-link class="site-button btn-half" to="/about"><span>Leer Más</span></router-link>
                </div>
              </div>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-12">
              <div class="video-section-full-v2">
                <div
                  class="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                  style="background-image: url(images/fotos/img9.jpeg)"
                >
                  <div class="overlay-main bg-black opacity-04"></div>
                  <div class="video-section-inner">
                    <div class="video-section-content">
                      <div class="video-section-bottom">
                        <h3 class="sx-title text-white">Mas de 2 años<br />de experiencia</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ABOUT COMPANY END -->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
