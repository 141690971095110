<template>
  <div class="section-full mobile-page-padding p-t80 p-b50">
    <div class="section-content">
      <div class="container">
        <div class="row" style="justify-content: center">
          <div v-for="(product, index) in productTypes" :key="index" class="col-lg-4 col-md-6 col-sm-12 m-b30">
            <div style="-webkit-box-shadow: 0 10px 30px 0 rgba(19, 18, 18, 0.287); box-shadow: 0 10px 30px 0 rgba(31, 30, 30, 0.377)">
              <div class="bg-white" style="height: 400px !important">
                <img :src="product.image" style="height: 100%" />
              </div>
              <div class="icon-content">
                <div class="text-left" style="height: 80px; background-color: #eef1f2 !important; padding: 10px">
                  <h4 class="sx-tilte" style="margin: 2px">{{ product.name }}</h4>
                  <router-link
                    :to="{
                      name: 'product-type',
                      query: {
                        productType: product.type,
                      },
                    }"
                  >
                    <a href="#" class="site-button-link">Ver Productos</a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hilite-title text-left p-l50 text-uppercase">
      <strong>PRODUCTOS</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: "producList",
  setup() {
    const productTypes = [
      {
        name: "Planchas Trapezoidales",
        image: "/images/product/CCH-825.jpg",
        type: "p-trapezoidales",
      },
      {
        name: "Planchas Acanaladas",
        image: "/images/product/CCH-ONDA3-4.png",
        type: "p-acanaladas",
      },

      {
        name: "Paneles Aislados",
        image: "/images/product/CCH-FRIGOPOLWALL.jpg",
        type: "p-aislados",
      },
      {
        name: "Hojalateria",
        image: "/images/product/CCH-CANALETAS.jpg",
        type: "hojalateria",
      },
    ];

    return { productTypes };
  },
};
</script>

<style></style>
