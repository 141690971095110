<template>
  <div id="headerBannerr">
    <div
      class="sx-bnr-inr overlay-wraper bg-parallax bg-top-center"
      data-stellar-background-ratio="0.5"
      style="background-image: url(images/contact/banner.jpg)"
    >
      <div class="overlay-main bg-black opacity-07"></div>
      <div class="container">
        <div class="sx-bnr-inr-entry">
          <div class="banner-title-outer">
            <div class="banner-title-name">
              <h2 class="m-tb0">SOBRE NOSOTROS</h2>
              <p>Una empresa especializada en el sur de Chile.</p>
            </div>
          </div>
          <!-- BREADCRUMB ROW -->

          <div>
            <ul class="sx-breadcrumb breadcrumb-style-2">
              <li><a href="/">Inicio</a></li>
              <li>Sobre Nosotros</li>
            </ul>
          </div>

          <!-- BREADCRUMB ROW END -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
