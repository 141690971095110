<template>
  <HeaderBanner></HeaderBanner>
  <AboutInformation></AboutInformation>
  <!-- <OurTeam></OurTeam> -->
  <!-- <OurClient></OurClient>
  <OurTecnology></OurTecnology> -->
</template>

<script>
import HeaderBanner from "../components/aboutUs/HeaderBanner";
import AboutInformation from "../components/aboutUs/AboutInformation";
// import OurTeam from "../components/aboutUs/OurTeam";
// import OurClient from "../components/aboutUs/OurClient";
// import OurTecnology from "../components/aboutUs/OurTecnology";
export default {
  name: "about",
  components: {
    HeaderBanner,
    AboutInformation,
    // OurTeam,
    // OurClient,
    // OurTecnology,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style></style>
