<template>
  <div id="contact">
    <!-- TESTIMONIAL START -->
    <div
      class="section-full mobile-page-padding bg-repeat p-t80 p-b80"
      style="background-image: url(images/contact/contact.jpg); background-size: cover"
    >
      <div class="section-content">
        <div class="container">
          <!-- TITLE START -->
          <div class="section-head">
            <div class="sx-separator-outer separator-center">
              <div class="sx-separator bg-white bg-moving bg-repeat-x" style="background-image: url(images/background/cross-line2.png)">
                <h3 class="sep-line-one">COMO PODEMOS AYUDARTE?</h3>
              </div>
              <div class="text-center p-t50">
                <router-link to="/contact" class="site-button btn-half"><span>CONTACTENOS</span></router-link>
              </div>
            </div>
          </div>
          <!-- TITLE END -->
        </div>
      </div>
    </div>
    <!-- TESTIMONIAL  END -->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
