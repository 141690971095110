<template>
  <div id="headerBannerr">
    <div
      class="sx-bnr-inr overlay-wraper bg-parallax bg-top-center"
      data-stellar-background-ratio="0.5"
      style="background-image: url(images/project/mallCostanera.jpeg)"
    >
      <div class="overlay-main bg-black opacity-07"></div>
      <div class="container">
        <div class="sx-bnr-inr-entry">
          <div class="banner-title-outer">
            <div class="banner-title-name">
              <h2 class="m-tb0">PROYECTOS</h2>
              <p style="max-width: 650px">
                CoverChile es una empresa ubicada en la X Región de Los Lagos, orientada a entregar a sus clientes una solución integral a
                través de la provisión e Instalación de Cubiertas y Revestimientos de acero pintados, como también de paneles Aislados.
                Contamos con más de 5 años de experiencia, otorgando excelencia en el servicio y calidad en los productos.
              </p>
            </div>
          </div>
          <!-- BREADCRUMB ROW -->

          <div>
            <ul class="sx-breadcrumb breadcrumb-style-2">
              <li><a href="/">Inicio</a></li>
              <li>Proyectos</li>
            </ul>
          </div>

          <!-- BREADCRUMB ROW END -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
