<template>
  <Load></Load>
  <NavBar></NavBar>
  <div class="page-content">
    <router-view />
  </div>
  <Footer></Footer>
  <ScrollTop></ScrollTop>
</template>

<script>
import NavBar from "./layout/NavBar";
import Footer from "./layout/Footer";
import ScrollTop from "./layout/ScrollTop";
import Load from "./layout/Load";
export default {
  name: "app",
  components: {
    NavBar,
    Footer,
    ScrollTop,
    Load,
  },
};
</script>

<style></style>
