<template>
  <div class="banner">
    <div
      id="bannerHomeSlide_wrapper"
      class="forcefullwidth_wrapper_tp_banner fullscreen-container"
      data-alias="mask-showcase"
      data-source="gallery"
      style="background: #aaaaaa; padding: 0px"
    >
      <!-- START REVOLUTION SLIDER 5.4.1 fullscreen mode -->
      <div id="bannerHomeSlide" class="rev_slider fullscreenbanner heightScreen" style="display: none" data-version="5.4.1">
        <ul>
          <!-- SLIDE 1 -->
          <li
            data-index="rs-70"
            data-transition="fade"
            data-slotamount="default"
            data-hideafterloop="0"
            data-hideslideonmobile="off"
            data-easein="default"
            data-easeout="default"
            data-masterspeed="300"
            data-thumb="images/banner/slide1.jpg"
            data-rotate="0"
            data-saveperformance="off"
            data-title=""
            data-param1="1"
            data-param2=""
            data-param3=""
            data-param4=""
            data-param5=""
            data-param6=""
            data-param7=""
            data-param8=""
            data-param9=""
            data-param10=""
            data-description=""
          >
            <!-- MAIN IMAGE -->
            <img
              src="images/banner/slide1.jpg"
              data-bgcolor="#f8f8f8"
              style=""
              alt=""
              data-bgposition="center center"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
              data-bgparallax="off"
              class="rev-slidebg"
              data-no-retina
            />

            <!-- LAYER 1  right image overlay dark-->
            <div
              class="tp-caption tp-shape tp-shapewrapper rs-parallaxlevel-tobggroup"
              id="slide-70-layer-1"
              data-x="['right','right','right','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['0','0','0','0']"
              data-fontweight="['100','100','400','400']"
              data-width="['full','full','full','full']"
              data-height="['full','full','full','full']"
              data-whitespace="nowrap"
              data-type="shape"
              data-basealign="slide"
              data-responsive_offset="off"
              data-responsive="off"
              data-frames='[{"from":"opacity:0;","speed":1500,"to":"o:1;","delay":150,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power2.easeInOut"}]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              style="z-index: 6; background-color: rgba(0, 0, 0, 0.5)"
            ></div>

            <!-- LAYER 5  Paragraph-->
            <div
              class="tp-caption tp-resizeme"
              id="slide-70-layer-5"
              data-x="['left','left','left','center']"
              data-hoffset="['50','50','70','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['80','80','80','80']"
              data-fontsize="['40','40','35','30']"
              data-lineheight="['40','40','40','30']"
              data-width="['600','600','600','380']"
              data-height="none"
              data-whitespace="normal"
              data-type="text"
              data-responsive_offset="on"
              data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;",
                                "mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":3500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},
                                {"delay":"wait","speed":500,"to":"y:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power1.easeIn"}]'
              data-textAlign="['left','left','left','center']"
              data-paddingtop="[20,20,20,20]"
              data-paddingright="[20,20,20,20]"
              data-paddingbottom="[30,30,30,30]"
              data-paddingleft="[0,0,0,0]"
              style="z-index: 10; white-space: normal; color: #fff; font-family: 'Poppins', sans-serif; font-weight: 700"
            >
              Productos para grandes proyectos
            </div>

            <!-- LAYER 6  Read More-->
            <div
              class="tp-caption rev-btn tp-resizeme"
              id="slide-70-layer-6"
              data-x="['left','left','left','center']"
              data-hoffset="['50','50','70','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['160','160','160','160']"
              data-width="none"
              data-height="none"
              data-whitespace="nowrap"
              data-type="button"
              data-responsive_offset="on"
              data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;",
                                "mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":4000,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},
                                {"delay":"wait","speed":500,"to":"y:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power1.easeIn"}]'
              data-textAlign="['left','left','left','center']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              style="z-index: 9; line-height: 30px"
            >
              <a href="Javascript:;" class="site-button btn-half"><span> Read More</span></a>
            </div>
          </li>

          <!-- SLIDE 2 -->
          <li
            data-index="rs-71"
            data-transition="fade"
            data-slotamount="default"
            data-hideafterloop="0"
            data-hideslideonmobile="off"
            data-easein="default"
            data-easeout="default"
            data-masterspeed="300"
            data-thumb="images/banner/ABALGARDEN.jpeg"
            data-rotate="0"
            data-saveperformance="off"
            data-title=""
            data-param1="1"
            data-param2=""
            data-param3=""
            data-param4=""
            data-param5=""
            data-param6=""
            data-param7=""
            data-param8=""
            data-param9=""
            data-param10=""
            data-description=""
          >
            <!-- MAIN IMAGE -->
            <img
              src="images/banner/ABALGARDEN.jpeg"
              data-bgcolor="#f8f8f8"
              style=""
              alt=""
              data-bgposition="center center"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
              data-bgparallax="off"
              class="rev-slidebg"
              data-no-retina
            />

            <!-- LAYER 1  right image overlay dark-->
            <div
              class="tp-caption tp-shape tp-shapewrapper rs-parallaxlevel-tobggroup"
              id="slide-71-layer-1"
              data-x="['right','right','right','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['0','0','0','0']"
              data-fontweight="['100','100','400','400']"
              data-width="['full','full','full','full']"
              data-height="['full','full','full','full']"
              data-whitespace="nowrap"
              data-type="shape"
              data-basealign="slide"
              data-responsive_offset="off"
              data-responsive="off"
              data-frames='[{"from":"opacity:0;","speed":1500,"to":"o:1;","delay":150,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power2.easeInOut"}]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              style="z-index: 6; background-color: rgba(0, 0, 0, 0.5)"
            ></div>

            <!-- LAYER 5  Paragraph-->
            <div
              class="tp-caption tp-resizeme"
              id="slide-71-layer-5"
              data-x="['left','left','left','center']"
              data-hoffset="['50','50','70','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['80','80','80','80']"
              data-fontsize="['40','40','35','30']"
              data-lineheight="['40','40','40','30']"
              data-width="['600','600','600','380']"
              data-height="none"
              data-whitespace="normal"
              data-type="text"
              data-responsive_offset="on"
              data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;",
                                "mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":3500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},
                                {"delay":"wait","speed":500,"to":"y:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power1.easeIn"}]'
              data-textAlign="['left','left','left','center']"
              data-paddingtop="[20,20,20,20]"
              data-paddingright="[20,20,20,20]"
              data-paddingbottom="[30,30,30,30]"
              data-paddingleft="[0,0,0,0]"
              style="z-index: 10; white-space: normal; color: #fff; font-family: 'Poppins', sans-serif; font-weight: 700"
            >
              Soluciones para diseños y arquitecturas innovadoras
            </div>

            <!-- LAYER 6  Read More-->
            <div
              class="tp-caption rev-btn tp-resizeme"
              id="slide-71-layer-6"
              data-x="['left','left','left','center']"
              data-hoffset="['50','50','70','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['160','160','160','160']"
              data-width="none"
              data-height="none"
              data-whitespace="nowrap"
              data-type="button"
              data-responsive_offset="on"
              data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;",
                                "mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":4000,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},
                                {"delay":"wait","speed":500,"to":"y:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power1.easeIn"}]'
              data-textAlign="['left','left','left','center']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              style="z-index: 9; line-height: 30px"
            >
              <a href="Javascript:;" class="site-button btn-half"><span> Read More</span></a>
            </div>
          </li>

          <!-- SLIDE 3 -->
          <li
            data-index="rs-72"
            data-transition="fade"
            data-slotamount="default"
            data-hideafterloop="0"
            data-hideslideonmobile="off"
            data-easein="default"
            data-easeout="default"
            data-masterspeed="300"
            data-thumb="images/project/mallCostanera.jpeg"
            data-rotate="0"
            data-saveperformance="off"
            data-title=""
            data-param1="1"
            data-param2=""
            data-param3=""
            data-param4=""
            data-param5=""
            data-param6=""
            data-param7=""
            data-param8=""
            data-param9=""
            data-param10=""
            data-description=""
          >
            <!-- MAIN IMAGE -->
            <img
              src="images/project/mallCostanera.jpeg"
              data-bgcolor="#f8f8f8"
              style=""
              alt=""
              data-bgposition="center center"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
              data-bgparallax="off"
              class="rev-slidebg"
              data-no-retina
            />

            <!-- LAYER 1  right image overlay dark-->
            <div
              class="tp-caption tp-shape tp-shapewrapper rs-parallaxlevel-tobggroup"
              id="slide-72-layer-1"
              data-x="['right','right','right','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['0','0','0','0']"
              data-fontweight="['100','100','400','400']"
              data-width="['full','full','full','full']"
              data-height="['full','full','full','full']"
              data-whitespace="nowrap"
              data-type="shape"
              data-basealign="slide"
              data-responsive_offset="off"
              data-responsive="off"
              data-frames='[{"from":"opacity:0;","speed":1500,"to":"o:1;","delay":150,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power2.easeInOut"}]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              style="z-index: 6; background-color: rgba(0, 0, 0, 0.5)"
            ></div>

            <!-- LAYER 5  Paragraph-->
            <div
              class="tp-caption tp-resizeme"
              id="slide-72-layer-5"
              data-x="['left','left','left','center']"
              data-hoffset="['50','50','70','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['80','80','80','80']"
              data-fontsize="['40','40','35','30']"
              data-lineheight="['40','40','40','30']"
              data-width="['600','600','600','380']"
              data-height="none"
              data-whitespace="normal"
              data-type="text"
              data-responsive_offset="on"
              data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;",
                                "mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":3500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},
                                {"delay":"wait","speed":500,"to":"y:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power1.easeIn"}]'
              data-textAlign="['left','left','left','center']"
              data-paddingtop="[20,20,20,20]"
              data-paddingright="[20,20,20,20]"
              data-paddingbottom="[30,30,30,30]"
              data-paddingleft="[0,0,0,0]"
              style="z-index: 10; white-space: normal; color: #fff; font-family: 'Poppins', sans-serif; font-weight: 700"
            >
              Fabricación de calidad
            </div>

            <!-- LAYER 6  Read More-->
            <div
              class="tp-caption rev-btn tp-resizeme"
              id="slide-72-layer-6"
              data-x="['left','left','left','center']"
              data-hoffset="['50','50','70','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['160','160','160','160']"
              data-width="none"
              data-height="none"
              data-whitespace="nowrap"
              data-type="button"
              data-responsive_offset="on"
              data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;",
                                "mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":4000,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},
                                {"delay":"wait","speed":500,"to":"y:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power1.easeIn"}]'
              data-textAlign="['left','left','left','center']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              style="z-index: 9; line-height: 30px"
            >
              <a href="Javascript:;" class="site-button btn-half"><span> Read More</span></a>
            </div>
          </li>
        </ul>
        <div class="tp-bannertimer" style="height: 10px; background: rgba(0, 0, 0, 0.15)"></div>
      </div>
    </div>
    <!-- SLIDER END -->
  </div>
</template>

<script>
export default {
  name: "banner",

  mounted() {
    this.$nextTick(function () {
      // eslint-disable-next-line no-undef
      jQuery("#bannerHomeSlide")
        .show()
        .revolution({
          sliderType: "standard",
          jsFileLocation: "revolution/js/",
          sliderLayout: "fullscreen",
          dottedOverlay: "none",
          delay: 9000,
          navigation: {
            keyboardNavigation: "off",
            keyboard_direction: "horizontal",
            mouseScrollNavigation: "off",
            mouseScrollReverse: "default",
            onHoverStop: "off",
            touch: {
              touchenabled: "on",
              touchOnDesktop: "off",
              swipe_threshold: 75,
              swipe_min_touches: 50,
              swipe_direction: "horizontal",
              drag_block_vertical: false,
            },

            arrows: {
              style: "uranus",
              enable: true,
              hide_onmobile: false,
              hide_onleave: false,
              left: {
                h_align: "left",
                v_align: "center",
                h_offset: 0,
                v_offset: 0,
              },
              right: {
                h_align: "right",
                v_align: "center",
                h_offset: 0,
                v_offset: 0,
              },
            },
            bullets: {
              enable: true,
              hide_onmobile: false,
              style: "hephaistos",

              hide_onleave: false,
              direction: "horizontal",
              h_align: "center",
              v_align: "bottom",
              h_offset: 0,
              v_offset: 30,
              space: 5,
              tmp: "",
            },
          },
          responsiveLevels: [1240, 1024, 778, 480],
          visibilityLevels: [1240, 1024, 778, 480],
          gridwidth: [1270, 1024, 778, 480],
          gridheight: [729, 768, 768, 720],
          lazyType: "none",
          parallax: {
            type: "scroll",
            origo: "slidercenter",
            speed: 2000,
            levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 51, 55],
          },
          shadow: 0,
          spinner: "off",
          stopLoop: "off",
          stopAfterLoops: -1,
          stopAtSlide: -1,
          shuffle: "off",
          autoHeight: "off",
          fullScreenAutoWidth: "off",
          fullScreenAlignForce: "off",
          fullScreenOffsetContainer: "",
          fullScreenOffset: "30px",
          hideThumbsOnMobile: "off",
          hideSliderAtLimit: 0,
          hideCaptionAtLimit: 0,
          hideAllCaptionAtLilmit: 0,
          debugMode: false,
          fallbacks: {
            simplifyAll: "off",
            nextSlideOnWindowFocus: "off",
            disableFocusListener: false,
          },
        });
    });
  },
};
</script>

<style scoped>
.heightScreen {
  height: 100vh !important;
}
</style>
