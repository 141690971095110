<template>
  <div id="productHome">
    <!-- OUR AWARDS SECTION START -->
    <div
      class="section-full p-t80 p-b50 sx-bg-secondry bg-no-repeat bg-bottom-center mobile-page-padding"
      style="background-image: url(images/background/bg-map.png)"
    >
      <div class="container">
        <!-- TITLE START -->
        <div class="section-head">
          <div class="sx-separator-outer separator-center">
            <div class="sx-separator bg-white bg-moving bg-repeat-x" style="background-image: url(images/background/cross-line2.png)">
              <h3 class="sep-line-one">PRODUCTOS</h3>
            </div>
          </div>
        </div>
        <!-- TITLE END -->

        <div class="section-content">
          <div class="row number-block-three-outer justify-content-center">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 m-b30">
              <div class="number-block-three slide-ani-top">
                <div class="sx-media">
                  <img style="height: 230px" src="images/product/CCH-40CURVA.jpg" alt="" />
                </div>
                <div class="figcaption bg-gray p-a30">
                  <h4 class="m-tb0" style="font-size: 18px"><a href="javascript:;">CCH-40 CURVO </a></h4>
                  <p style="margin-bottom: 0px">PLANCHA TRAPEZOIDAL</p>
                  <div class="figcaption-number text-black animate-top-content">
                    <span>01</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 m-b30">
              <div class="number-block-three slide-ani-top">
                <div class="sx-media">
                  <img style="height: 230px; background-color: #c4c5c32e" src="images/product/CCH-CUMBRERA.png" alt="" />
                </div>
                <div class="figcaption bg-gray p-a30">
                  <h4 class="m-tb0" style="font-size: 18px"><a href="javascript:;">CCH-CUMBRERA</a></h4>
                  <p style="margin-bottom: 0px">HOJALATERIA</p>
                  <div class="figcaption-number text-black animate-top-content">
                    <span>02</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 m-b30">
              <div class="number-block-three slide-ani-top">
                <div class="sx-media">
                  <img src="images/fotos/img10.jpeg" alt="" />
                </div>
                <div class="figcaption bg-gray p-a30">
                  <h4 class="m-tb0"><a href="javascript:;">Planning</a></h4>

                  <div class="figcaption-number text-black animate-top-content">
                    <span>02</span>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 m-b30">
              <div class="number-block-three slide-ani-top">
                <div class="sx-media">
                  <img style="height: 230px; background-color: #c4c5c32e" src="images/product/CCH-ONDA3-4.png" alt="" />
                </div>
                <div class="figcaption bg-gray p-a30">
                  <h4 class="m-tb0" style="font-size: 18px"><a href="javascript:;">CCH-ONDA 3/4</a></h4>
                  <p style="margin-bottom: 0px">PLANCHA ANACALADA</p>
                  <div class="figcaption-number text-black animate-top-content">
                    <span>03</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 m-b30">
              <div class="number-block-three slide-ani-top">
                <div class="sx-media">
                  <img src="images/fotos/img14.jpeg" alt="" />
                </div>
                <div class="figcaption bg-gray p-a30">
                  <h4 class="m-tb0"><a href="javascript:;">Exterior</a></h4>

                  <div class="figcaption-number text-black animate-top-content">
                    <span>03</span>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 m-b30">
              <div class="number-block-three slide-ani-top">
                <div class="sx-media">
                  <img style="height: 230px; background-color: #c4c5c32e" src="images/product/CCH-FRIGOPOLLISO.png" alt="" />
                </div>
                <div class="figcaption bg-gray p-a30">
                  <h4 class="m-tb0" style="font-size: 18px"><a href="javascript:;">CCH-FRIGOPOL LISO</a></h4>
                  <p style="margin-bottom: 0px">PANEL AISLADO</p>
                  <div class="figcaption-number text-black animate-top-content">
                    <span>04</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center load-more-btn-outer" style="background-image: url(images/background/cross-line.png)">
              <a class="site-button btn-half" href="/#/product"><span>Ver Más</span></a>
              <!-- <router-link class="site-button-secondry btn-half" to="/product"><span>Ver Más</span></router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- OUR AWARDS SECTION END -->
  </div>
</template>

<script>
export default {
  name: "productHome",
};
</script>

<style></style>
