<template>
  <header class="site-header nav-wide nav-transparent">
    <!-- <NavbarTop></NavbarTop> -->
    <NavbarScroll></NavbarScroll>
  </header>
</template>

<script>
// import NavbarTop from "../layout/NavbarTop";
import NavbarScroll from "../layout/NavbarScroll";
export default {
  name: "navbar",
  components: {
    // NavbarTop,
    NavbarScroll,
  },
};
</script>

<style></style>
