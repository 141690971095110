import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Contact from "../views/Contact.vue";
import About from "../views/About.vue";
import Project from "../views/Project.vue";
import Product from "../views/Product.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/project",
    name: "Project",
    component: Project,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },

  {
    path: "/product/type",
    name: "product-type",
    component: () => import("../components/product/ProductType.vue" /* webpackChunkName: "prodyctType" */),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
// eslint-disable-next-line no-unused-vars

export default router;
