<template>
  <HeaderBanner></HeaderBanner>
  <ContactForm></ContactForm>
</template>

<script>
import HeaderBanner from "../components/contact/HeaderBanner";
import ContactForm from "../components/contact/ContactForm";
export default {
  name: "contact",
  components: {
    HeaderBanner,
    ContactForm,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style></style>
