<template>
  <div id="aboutInformation">
    <!-- ABOUT COMPANY START -->
    <div class="section-full mobile-page-padding p-t80 p-b80 bg-gray">
      <div class="container">
        <div class="section-content">
          <div class="row">
            <!-- <div class="col-xl-5 col-lg-5 col-md-12">
              <div
                class="home-2-about bg-bottom-left bg-no-repeat bg-cover"
                style="background-image: url(images/about/team/equipoTrabajo.jpeg); border: 1px solid #23252d"
              ></div>
            </div> -->

            <div class="col-xl-12 col-lg-12 col-md-12">
              <div class="about-home-2">
                <h3 class="m-t0 sx-tilte">Descripción Empresa</h3>
                <p>
                  Somos una empresa ubicada en la X Region de Los Lagos, orientada a entregarle a sus clientes una solución integral a
                  través de la provisión e instalación de Cubiertas y Revestimientos de acero pintados, como también de paneles Aislados
                  POL, PIR.
                </p>
                <!-- <div class="text-left">
                  <a href="javascript:;" class="site-button-link">Read More</a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ABOUT COMPANY END -->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
