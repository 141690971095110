<template>
  <div id="project">
    <div class="section-full mobile-page-padding bg-white p-t80 p-b50 mobile-page-padding">
      <div class="container">
        <!-- TITLE START -->
        <div class="section-head">
          <div class="sx-separator-outer separator-center">
            <div class="sx-separator bg-white bg-moving bg-repeat-x" style="background-image: url(images/background/cross-line2.png)">
              <h3 class="sep-line-one">PROYECTOS</h3>
            </div>
          </div>
        </div>
        <!-- TITLE END -->

        <!-- IMAGE CAROUSEL START -->
        <div class="section-content">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="blog-post blog-grid date-style-2">
                <div class="sx-post-media sx-img-effect img-reflection">
                  <a href="/project"><img src="/images/project/edificioAvangarde.jpg" alt="" style="height: 380px" /></a>
                </div>

                <div class="sx-post-info p-t30">
                  <div class="sx-post-meta">
                    <ul>
                      <li class="post-date"><span>Año</span><span style="font-weight: 600">2019</span></li>
                    </ul>
                  </div>
                  <div class="sx-post-title">
                    <h4 class="post-title"><a href="javascript:void(0);">Edificio Avantgarden - Puerto Montt</a></h4>
                  </div>

                  <div class="sx-post-readmore">
                    <router-link class="site-button-link" to="/project">Ver Más</router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="blog-post blog-grid date-style-2">
                <div class="sx-post-media sx-img-effect img-reflection">
                  <a href="/project"><img src="/images/project/project4.jpg" alt="" style="height: 380px" /></a>
                </div>

                <div class="sx-post-info p-t30">
                  <div class="sx-post-meta">
                    <ul>
                      <li class="post-date">
                        <span>Año</span>
                        <span style="font-weight: 600">2020</span>
                        <span style="font-weight: 600">2021</span>
                      </li>
                      <!-- <li class="post-author">
                        <a href="javascript:void(0);">By <span>John</span></a>
                      </li>
                      <li class="post-comment"><a href="javascript:void(0);">5 Comments</a></li> -->
                    </ul>
                  </div>
                  <div class="sx-post-title">
                    <h4 class="post-title"><a href="javascript:void(0);">Edificio Nomada - Puerto Varas</a></h4>
                  </div>

                  <div class="sx-post-readmore">
                    <router-link class="site-button-link" to="/project">Ver Más</router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="blog-post blog-grid date-style-2">
                <div class="sx-post-media sx-img-effect img-reflection">
                  <a href="/project"><img src="/images/project/project3.jpg" alt="" style="height: 380px" /></a>
                </div>

                <div class="sx-post-info p-t30">
                  <div class="sx-post-meta">
                    <ul>
                      <li class="post-date"><span>Año</span><span style="font-weight: 600">2021</span></li>
                      <!-- <li class="post-author">
                        <a href="javascript:void(0);">By <span>John</span></a>
                      </li> -->
                      <!-- <li class="post-comment"><a href="javascript:void(0);">5 Comments</a></li> -->
                    </ul>
                  </div>
                  <div class="sx-post-title">
                    <h4 class="post-title"><a href="javascript:void(0);">Edificios Rocura - Valdivia</a></h4>
                  </div>

                  <div class="sx-post-readmore">
                    <router-link class="site-button-link" to="/project">Ver Más</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
