import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import $ from "jquery";

window.$ = $;

const app = createApp(App);
app.use(router);
app.use($);

// FONT AWESOME 5
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

//Inicioando APP
app.mount("#app");
