<template>
  <HeaderBanner></HeaderBanner>
  <ProductList></ProductList>
</template>

<script>
import { watchEffect } from "vue";
import HeaderBanner from "../components/product/HeaderBanner";
import ProductList from "../components/product/ProductList";
export default {
  name: "product",
  components: {
    HeaderBanner,
    ProductList,
  },
  setup() {
    watchEffect(() => {
      window.scrollTo(0, 0);
    });
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style></style>
