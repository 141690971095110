<template>
  <div id="contactForm">
    <div class="section-full p-tb80 inner-page-padding">
      <!-- LOCATION BLOCK-->
      <div class="container">
        <!-- GOOGLE MAP & CONTACT FORM -->
        <div class="section-content">
          <!-- CONTACT FORM-->
          <div class="row">
            <div class="col-lg-8 col-md-12 col-sm-12">
              <div class="contact-form cons-contact-form bg-gray p-a30">
                <div class="contact-one">
                  <!-- TITLE START -->
                  <div class="section-head">
                    <div class="sx-separator-outer separator-left">
                      <div
                        class="sx-separator bg-white bg-moving bg-repeat-x"
                        style="background-image: url(images/background/cross-line2.png)"
                      >
                        <h3 class="sep-line-one">CONTACTENOS</h3>
                      </div>
                    </div>
                  </div>
                  <!-- TITLE END -->
                  <div class="form-group">
                    <h5>NO ESPERES MÁS, PARA INICIAR TU PROYECTO JUNTO A NOSOTROS</h5>
                  </div>
                  <div class="form-group">
                    <p>
                      Somos una empresa con mucha experiencia en la instalación de Cubiertas y Revestimientos de Aceros Pintados. <br />
                      Trabajamos con productos de gran calidad dentro del mercado.
                    </p>
                  </div>

                  <!-- <div class="form-group">
                    <span
                      >Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo nihil commodi ea consectetur laudantium harum,
                      dolorem eligendi. Consequatur numquam maiores facere, deleniti mollitia tempora ad excepturi quidem illo eius
                      voluptas?</span
                    >
                  </div> -->
                  <div class="text-right">
                    <a class="site-button btn-half" href="mailto: contacto@coverchile.cl"><span> Enviar Solicitud</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="contact-info block-shadow bg-white bg-center p-a40" style="background-image: url(images/background/bg-map.png)">
                <div>
                  <!-- TITLE START -->
                  <div class="section-head">
                    <div class="sx-separator-outer separator-left">
                      <div
                        class="sx-separator bg-white bg-moving bg-repeat-x"
                        style="background-image: url(images/background/cross-line2.png)"
                      >
                        <h3 class="sep-line-one">INFO.</h3>
                      </div>
                    </div>
                  </div>
                  <!-- TITLE END -->
                  <div class="sx-icon-box-wraper left p-b30">
                    <div class="icon-xs"><i class="fa fa-phone"></i></div>
                    <div class="icon-content">
                      <h5 class="m-t0">TELEFONO DE CONTACTO</h5>
                      <p>+56 9 5687 5957</p>
                    </div>
                  </div>

                  <div class="sx-icon-box-wraper left p-b30">
                    <div class="icon-xs"><i class="fa fa-envelope"></i></div>
                    <div class="icon-content">
                      <h5 class="m-t0">CORREO</h5>
                      <p>contacto@coverchile.cl</p>
                    </div>
                  </div>

                  <div class="sx-icon-box-wraper left">
                    <div class="icon-xs"><i class="fa fa-map-marker"></i></div>
                    <div class="icon-content">
                      <h5 class="m-t0">DIRECCIÓN</h5>
                      <p>
                        Puerto Montt - Camino la vara. (Alerce) KM 3.5 <br />
                        X Región de Los Lagos, Chile.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
