<template>
  <!-- OUR SPECIALLIZATION START -->
  <div class="section-full mobile-page-padding p-t80 bg-white bg-change-section">
    <div class="container">
      <!-- TITLE START -->
      <div class="section-head">
        <div class="sx-separator-outer separator-center">
          <div class="sx-separator bg-white bg-moving bg-repeat-x" style="background-image: url(images/background/cross-line2.png)">
            <h3 class="sep-line-one title-project" style="text-align: center">ALGUNOS DE NUESTROS PROYECTOS</h3>
          </div>
        </div>
      </div>
      <!-- TITLE END -->
    </div>
    <div class="owl-carousel service-slider-two owl-btn-vertical-center">
      <div class="item">
        <div
          class="bgcall-block d-flex flex-wrap justify-content-center align-content-end bg-cover overlay-wraper"
          style="background-image: url(images/project/edificioAvangarde.jpg)"
        >
          <div class="overlay-main bg-black opacity-05"></div>
          <div class="bg-content-mid-outer">
            <div class="bg-content-mid">
              <div class="sx-icon-box-wraper center text-white">
                <div class="icon-lg m-b15">
                  <span class="icon-cell"><i class="flaticon-skyline"></i></span>
                </div>
                <div class="icon-content">
                  <h4 class="sx-tilte">
                    <a href="Javascript:;" class="sx-text-white"> Edificio Avant Garde</a>
                  </h4>
                </div>
              </div>
              <span class="bgcall-block-number" style="">01</span>
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="item">
        <div
          class="bgcall-block d-flex flex-wrap justify-content-center align-content-end bg-cover overlay-wraper"
          style="background-image: url(images/project/project4.jpg)"
        >
          <div class="overlay-main bg-black opacity-05"></div>
          <div class="bg-content-mid-outer">
            <div class="bg-content-mid">
              <div class="sx-icon-box-wraper center text-white">
                <div class="icon-lg m-b15">
                  <span class="icon-cell"><i class="flaticon-skyline"></i></span>
                </div>
                <div class="icon-content">
                  <h4 class="sx-tilte">
                    <a href="Javascript:;" class="sx-text-white">Edificio Nomada</a>
                  </h4>
                </div>
              </div>
              <span class="bgcall-block-number">02</span>
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="item">
        <div
          class="bgcall-block d-flex flex-wrap justify-content-center align-content-end bg-cover overlay-wraper"
          style="background-image: url(images/project/project3.jpg)"
        >
          <div class="overlay-main bg-black opacity-05"></div>
          <div class="bg-content-mid-outer">
            <div class="bg-content-mid">
              <div class="sx-icon-box-wraper center text-white">
                <div class="icon-lg m-b15">
                  <span class="icon-cell"><i class="flaticon-skyline"></i></span>
                </div>
                <div class="icon-content">
                  <h4 class="sx-tilte">
                    <a href="Javascript:;" class="sx-text-white">Edificios Rocura II</a>
                  </h4>
                </div>
              </div>
              <span class="bgcall-block-number">03</span>
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="item">
        <div
          class="bgcall-block d-flex flex-wrap justify-content-center align-content-end bg-cover overlay-wraper"
          style="background-image: url(images/project/project5.jpg)"
        >
          <div class="overlay-main bg-black opacity-05"></div>
          <div class="bg-content-mid-outer">
            <div class="bg-content-mid">
              <div class="sx-icon-box-wraper center text-white">
                <div class="icon-lg m-b15">
                  <span class="icon-cell"><i class="flaticon-skyline"></i></span>
                </div>
                <div class="icon-content">
                  <h4 class="sx-tilte">
                    <a href="Javascript:;" class="sx-text-white"> Edificios Sargento Silva</a>
                  </h4>
                </div>
              </div>
              <span class="bgcall-block-number">04</span>
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="item">
        <div
          class="bgcall-block d-flex flex-wrap justify-content-center align-content-end bg-cover overlay-wraper"
          style="background-image: url(images/project/project6.jpg)"
        >
          <div class="overlay-main bg-black opacity-05"></div>
          <div class="bg-content-mid-outer">
            <div class="bg-content-mid">
              <div class="sx-icon-box-wraper center text-white">
                <div class="icon-lg m-b15">
                  <span class="icon-cell"><i class="flaticon-sketch"></i></span>
                </div>
                <div class="icon-content">
                  <h4 class="sx-tilte">
                    <a href="Javascript:;" class="sx-text-white">Mall Chino </a>
                  </h4>
                </div>
              </div>
              <span class="bgcall-block-number">05</span>
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="item">
        <div
          class="bgcall-block d-flex flex-wrap justify-content-center align-content-end bg-cover overlay-wraper"
          style="background-image: url(images/project/project8.jpg)"
        >
          <div class="overlay-main bg-black opacity-05"></div>
          <div class="bg-content-mid-outer">
            <div class="bg-content-mid">
              <div class="sx-icon-box-wraper center text-white">
                <div class="icon-lg m-b15">
                  <span class="icon-cell"><i class="flaticon-sketch"></i></span>
                </div>
                <div class="icon-content">
                  <h4 class="sx-tilte">
                    <a href="Javascript:;" class="sx-text-white">Petrohue</a>
                  </h4>
                </div>
              </div>
              <span class="bgcall-block-number">06</span>
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div
          class="bgcall-block d-flex flex-wrap justify-content-center align-content-end bg-cover overlay-wraper"
          style="background-image: url(images/project/project9.jpg)"
        >
          <div class="overlay-main bg-black opacity-05"></div>
          <div class="bg-content-mid-outer">
            <div class="bg-content-mid">
              <div class="sx-icon-box-wraper center text-white">
                <div class="icon-lg m-b15">
                  <span class="icon-cell"><i class="flaticon-sketch"></i></span>
                </div>
                <div class="icon-content">
                  <h4 class="sx-tilte">
                    <a href="Javascript:;" class="sx-text-white">Chacabuco</a>
                  </h4>
                </div>
              </div>
              <span class="bgcall-block-number">07</span>
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div
          class="bgcall-block d-flex flex-wrap justify-content-center align-content-end bg-cover overlay-wraper"
          style="background-image: url(images/project/mallCostanera2.jpeg)"
        >
          <div class="overlay-main bg-black opacity-05"></div>
          <div class="bg-content-mid-outer">
            <div class="bg-content-mid">
              <div class="sx-icon-box-wraper center text-white">
                <div class="icon-lg m-b15">
                  <span class="icon-cell"><i class="flaticon-skyline"></i></span>
                </div>
                <div class="icon-content">
                  <h4 class="sx-tilte">
                    <a href="Javascript:;" class="sx-text-white">Mall Costanera</a>
                  </h4>
                </div>
              </div>
              <span class="bgcall-block-number">08</span>
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- OUR SPECIALLIZATION END -->
</template>

<script>
export default {
  name: "project-carrusel",

  mounted() {
    this.$nextTick(function () {
      // eslint-disable-next-line no-undef
      jQuery(".service-slider-two").owlCarousel({
        loop: true,
        autoplay: false,
        center: false,
        margin: 0,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive: {
          0: {
            items: 1,
          },
          640: {
            items: 2,
          },
          768: {
            items: 2,
          },
          991: {
            items: 3,
          },
          1200: {
            items: 5,
          },
        },
      });
    });
  },
};
</script>

<style scoped>
@media (max-width: 414px) {
  .title-project {
    font-size: 20px;
  }
}
</style>
